<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card class="header-page-appjogo">
          <b-row align-v="center">
            <b-col sm="1">
              <div class="icone-header-page">
                <img src="/assets/images/modal_icons/5_aplicação do jogo.png" />
              </div>
            </b-col>

            <b-col md="12">
              <h1 class="titulo-header-page text-white">
                Nova Aplicação de Jogo
              </h1>
              <!-- <h4 class="text-white">Inicie uma Aplicação de Jogo:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="!initiated">
        <b-card>
          <b-col md="12">
            <b-card-group columns>
              <b-card
                title="Inicie a Aplicação de um Jogo"
                class="position-static"
              >
                <b-card-text>
                  Lorem ipsum dolor sit amet. Ex repellat eos impedit atque ea
                  vitae fugit rem illum ipsa et voluptatibus excepturi non
                  soluta Quis! Ea molestias nulla est dolor quidem qui expedita
                  minus ab optio omnis ut velit nihil.
                </b-card-text>

                <b-button size="lg" variant="primary" @click="startGame()">
                  Começar!
                </b-button>

                <b-card-text> </b-card-text>
              </b-card>
            </b-card-group>
          </b-col>
        </b-card>
      </b-col>

      <b-col v-if="initiated">
        <Unity :unity="unityContext" width="960" height="600" ref="unity" />
      </b-col>
    </b-row>

    <b-button
      class="float-right mb-5"
      size="lg"
      variant="primary"
      @click="endGame()"
      v-if="initiated"
    >
      Finalizar Aplicação de Jogo
    </b-button>
    <b-button
      class="float-right mb-5 mr-1"
      size="lg"
      variant="primary"
      @click="maximizar()"
      v-if="initiated"
    >
      Maximizar Janela
    </b-button>
  </div>
</template>


<script>
import {
  BCard,
  BCardGroup,
  BCardText,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required, email } from '@validations'
import { codeIcon } from './code'

import UnityWebgl from 'unity-webgl'

const Unity = new UnityWebgl({
  loaderUrl: '/assets/breakout/buildweb_3005.loader.js',
  dataUrl: '/assets/breakout/buildweb_3005.data',
  frameworkUrl: '/assets/breakout/buildweb_3005.framework.js',
  codeUrl: '/assets/breakout/buildweb_3005.wasm',
})

export default {
  components: {
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BCardText,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Unity: UnityWebgl.vueComponent,
  },

  data() {
    return {
      unityContext: Unity,
      initiated: false,
      UserSelecionado: '',
      TesteSelecionado: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      TipoTeste: [
        { value: 'testcog_tipo1', text: 'Tipo de Teste 1' },
        { value: 'testcog_tipo2', text: 'Tipo de Teste 2' },
        { value: 'testcog_tipo3', text: 'Tipo de Teste 3' },
        { value: 'testcog_tipo4', text: 'Tipo de Teste 4' },
        { value: 'testcog_tipo5', text: 'Tipo de Teste 5' },
        { value: 'testcog_tipo6', text: 'Tipo de Teste 6' },
        { value: 'testcog_tipo7', text: 'Tipo de Teste 7' },
        { value: 'testcog_tipo8', text: 'Tipo de Teste 8' },
      ],

      NomeUser: [
        { value: 'user1', text: 'Jonatan Neves' },
        { value: 'user2', text: 'Artur Tavares' },
        { value: 'user3', text: 'Henrique Formiga' },
        { value: 'user4', text: 'Johnny Rodrigues' },
      ],
    }
  },
  computed: {
    getSessions() {
      return window.sessions
    },
    getLevel() {
      return window.levels
    },
    getLevelSettings() {
      return window.levelSettings
    },
  },
  methods: {
    maximizar() {
      this.unityContext.setFullscreen(0)
      this.unityContext.setFullscreen(1)
    },
    endGame() {
      console.log(this.getSessions)
      console.log(this.getLevel)
      console.log(this.getLevelSettings)
    },
    startGame() {
      this.initiated = true
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
